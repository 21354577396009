<template>
	<div id="page6" ref="page6">
		<div class="layout" v-if="option_ready">
			
			<div class="part">
				<div class="gauche">
					<h3>
						{{ $store.state.textes.page5.machine_options
						}}<span>
							<money-format :value="sous_total_machines * taux_de_change"
								:currency-code="$store.state.offre_active.devise"
								:subunits-value="false" :hide-subunits="false"
								:locale="$store.state.offre_active.langue">
							</money-format>
						</span>
					</h3>
				</div>
				<div class="droite">
					<hr />
					<ol>
						<Puce class="subtotal-machines" v-for="item in options.options_machines"
							:item="item" :key="`${item}-${item.id}-${item.masked}`"
							@update-item-visibility="handleItemVisibilityMachines"
							@update-item-custom-text="handleCustomTexteMachines"
							@update-item-price="handleUpdatePriceMachines" v-on:totalChange="makeTotal" />
					</ol>
				</div>
			</div> 
			<div class="part">
				<div class="gauche">
					<h3>
						{{ $store.state.textes.page6.accessoires_options }}
						<span>
							<money-format :value="sous_total_accessoires * taux_de_change"
								:currency-code="$store.state.offre_active.devise"
								:subunits-value="false" :hide-subunits="false"
								:locale="$store.state.offre_active.langue">
							</money-format>
							<!-- {{ sous_total_accessoires | currency }} -->
						</span>
					</h3>
				</div>
				<div class="droite">
					<hr />
					<ol>
						<Puce class="subtotal-accessoires" v-for="item in options.options_accessoires"
							:item="item" :key="item.id" @update-item-visibility="handleItemVisibility"
							@update-item-custom-text="handleCustomTexte"
							@update-item-price="handleUpdatePrice" @totalChange="makeTotal" />
					</ol>
					<div class="galerie_accessoires">
						<div class="image" v-for="image in images_accessoires" :key="image.id">
							<img :src="image.fields.image[0].thumbnails.large.url" />
							<legend v-if="$store.state.offre_active.langue == 'fr'"
								v-html="image.fields.legende_fr"></legend>
							<legend v-if="$store.state.offre_active.langue == 'en'"
								v-html="image.fields.legende_en"></legend>
							<legend v-if="$store.state.offre_active.langue == 'us'"
								v-html="image.fields.legende_us"></legend>
							<legend v-if="$store.state.offre_active.langue == 'ro'"
								v-html="image.fields.legende_ro"></legend>
							<legend v-if="$store.state.offre_active.langue == 'de'"
								v-html="image.fields.legende_de"></legend>
							<legend v-if="$store.state.offre_active.langue == 'pl'"
								v-html="image.fields.legende_pl"></legend>
						</div>
					</div>
				</div>
			</div>

			<div class="part">
				<div class="gauche">
					<h3>
						{{ $store.state.textes.page6.options_services }}
						<span>
							<money-format :value="sous_total_services * taux_de_change"
								:currency-code="$store.state.offre_active.devise"
								:subunits-value="false" :hide-subunits="false"
								:locale="$store.state.offre_active.langue">
							</money-format>
						</span>
					</h3>
				</div>
				<div class="droite">
					<hr />
					<ol>
						<Puce class="subtotal-services" v-for="item in options.options_services"
							:item="item" :key="item.id" @update-item-visibility="handleItemVisibilityServices"
							@update-item-custom-text="handleCustomTexteServices"
							@update-item-price="handleUpdatePriceServices" @totalChange="makeTotal" />
					</ol>
				</div>
			</div>

			
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page6.count" />
		</div>
	</div>
</template>

<script>
import Airtable from "airtable";
import Puce from "../puce";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");
import MoneyFormat from "vue-money-format";
import { mapActions } from "vuex";
export default {
	name: "page6",
	components: {
		MoneyFormat,
		Puce,
	},
	data() {
		return {
			sous_total_machines: 0,
			sous_total_accessoires: 0,
			sous_total_services: 0,
			total: 0,
			options: {
				options_machines: [],
				options_accessoires: [],
				options_services: [],
			},
			option_ready: false,
			photo_accessoires: [],
			taux_de_change: 1,
		};
	},
	computed: {
		images_accessoires: {
			get() {
				return this.photo_accessoires;
			},
			set() {
				return this.photo_accessoires;
			},
		},
	},
	mounted() {
		this.getOptions();
		this.photo_accessoires = [];
		if (this.$store.state.offre_active.devise != "EUR") {
			if (this.$store.state.offre_active.taux_de_change_perso) {
				this.taux_de_change = this.$store.state.offre_active.taux_de_change_perso;
			} else {
				this.taux_de_change = this.$store.state.currencies[this.$store.state.offre_active.devise];
			}
		}
		base("photos_options_accessoires")
			.select({
				maxRecords: 4,
				view: "photos",
			})
			.eachPage(
				(records, fetchNextPage) => {
					records.forEach((record) => {
						this.photo_accessoires.push(record);
					});
					fetchNextPage();
				},
				(err) => {
					if (err) {
						console.error(err);
						return;
					}
				}
			);
	},
	methods: {
		...mapActions(["updateOptionsAccessoires", "updateOptionsMachines", "updateOptionsServices", "editSousTotal"]),
		mergeObjects(array1, array2) {
			let mergedObjects = {};
			array1.forEach(obj => {
				mergedObjects[obj.id] = obj;
			});

			// Parcourir le second tableau et fusionner les objets
			array2.forEach(obj => {
				if (mergedObjects[obj.id]) {
					// Fusionner l'objet du array2 avec celui du array1 tout en conservant les propriétés non conflictuelles
					mergedObjects[obj.id] = { ...mergedObjects[obj.id], ...obj };
				} else {
					// Ajouter l'objet du array2 s'il n'existe pas dans array1
					mergedObjects[obj.id] = obj;
				}
			});

			// Convertir l'objet résultant en tableau
			let mergedArray = Object.values(mergedObjects);
			return mergedArray;
		},
		handleUpdatePrice(payload) {
			const index = this.options.options_accessoires.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_accessoires, index, {
					...this.options.options_accessoires[index],
					prix: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleUpdatePriceMachines(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					prix: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleCustomTexte(payload) {
			const index = this.options.options_accessoires.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_accessoires, index, {
					...this.options.options_accessoires[index],
					custom_text: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);

			}
		},
		handleCustomTexteMachines(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					custom_text: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
				
			}
		},
		handleItemVisibilityMachines(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					masked: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleItemVisibility(payload) {
			const index = this.options.options_accessoires.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_accessoires, index, {
					...this.options.options_accessoires[index],
					masked: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleItemVisibilityServices(payload) {
			const index = this.options.options_services.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_services, index, {
					...this.options.options_services[index],
					masked: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleCustomTexteServices(payload) {
			const index = this.options.options_services.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_services, index, {
					...this.options.options_services[index],
					custom_text: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleUpdatePriceServices(payload) {
			const index = this.options.options_services.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_services, index, {
					...this.options.options_services[index],
					prix: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						console.log(err, records);
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
		handleCommandeTexte(payload) {
			const index = this.options.options_commandes.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_commandes, index, {
					...this.options.options_commandes[index],
					custom_text: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);

			}
		},
		handleCommande(payload) {
			const index = this.options.options_commandes.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_commandes, index, {
					...this.options.options_commandes[index],
					masked: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		makeTotal() {
			//Je sauvegarde les options machines
			let temp = [];
			this.options.options_accessoires.map((item) => {
				temp.push({
					id: item.id,
					prix: item.prix,
					masked: item.masked,
					custom_text: item.custom_text,
				});
			});
			this.updateOptionsAccessoires(temp);

			let temp2 = [];
			this.options.options_machines.map((item) => {
				temp2.push({
					id: item.id,
					prix: item.prix,
					masked: item.masked,
					custom_text: item.custom_text,
				});
			});
			this.updateOptionsMachines(temp2);

			let temp3 = [];
			this.options.options_services.map((item) => {
				temp3.push({
					id: item.id,
					prix: item.prix,
					masked: item.masked,
					custom_text: item.custom_text,
				});
			});
			this.updateOptionsServices(temp3);

			// LES SOUS TOTAUX
			this.sous_total_accessoires = 0;
			this.options.options_accessoires.map((item) => {
				if (!item.masked) {
					this.sous_total_accessoires += parseInt(item.prix);
				}
			});

			this.sous_total_machines = 0;
			this.options.options_machines.map((item) => {
				if (!item.masked) {
					this.sous_total_machines += parseInt(item.prix);
				}
			});

			this.sous_total_services = 0;
			this.options.options_services.map((item) => {
				if (!item.masked) {
					this.sous_total_services += parseInt(item.prix);
				}
			});

			this.editSousTotal({
				field: "sous_total_accessoires",
				value: this.sous_total_accessoires,
			});				

			this.editSousTotal({
				field: "sous_total_machines",
				value: this.sous_total_machines,
			});

			this.editSousTotal({
				field: "sous_total_services",
				value: this.sous_total_services,
			});

			this.total =
				this.$store.state.offre_active.sous_total_machines +
				this.$store.state.offre_active.sous_total_commandes + 
				this.$store.state.offre_active.sous_total_accessoires +
				this.$store.state.offre_active.sous_total_services +
				parseInt(this.$store.state.offre_active.machine.prix_distrib);

			this.$store.commit("totaux_page_6", {
				a: this.sous_total_commandes,
				b: this.sous_total_accessoires,
				c: this.total,
			});
		},
		getOptions() {
			var options_machines =  [];
			var options_accessoires = [];
			var options_services = [];
			var options_inclues = [];
			var all_options = this.$store.state.options;
			all_options.options_machines?.map((item) => {
				if (item.fields[this.$route.params.machine] != "impossible") {
					if (item.fields[this.$route.params.machine] == "comprise") {
						item["prix"] = 0;
						options_inclues.push(item);
					} else {
						let prix_distrib = parseInt(item.fields[this.$route.params.machine]);
						let difference = Math.round(prix_distrib * this.$store.state.offre_active.gamme_de_prix_machine[0]);
						item["prix"] = prix_distrib + difference;
						item.masked = true;
						if (item.fields.reserve_picot) {
							item["modifiable"] = true;
						}
						if (this.$store.state.user.role.administrator) {
							options_machines.push(item);
						} else {
							if (!item.fields.reserve_picot) {
								options_machines.push(item);
							}
						}
					}
				}
			});

			all_options.options_accessoires.map((item) => {
				if (item.fields[this.$route.params.machine] != "impossible") {
					if (item.fields[this.$route.params.machine] == "comprise") {
						item["prix"] = 0;
						options_accessoires.push(item);
					} else {
						let prix_distrib = parseInt(item.fields[this.$route.params.machine]);
						let difference = Math.round(prix_distrib * this.$store.state.offre_active.gamme_de_prix_options[0]);
						item["prix"] = prix_distrib + difference;
						item.masked = true;
						if (item.fields.reserve_picot) {
							item["modifiable"] = true;
						}
						if (this.$store.state.user.role.administrator) {
							options_accessoires.push(item);
						} else {
							if (!item.fields.reserve_picot) {
								options_accessoires.push(item);
							}
						}
					}
				}
			});

			all_options.options_services.map((item) => {
				if (item.fields[this.$route.params.machine] != "impossible") {
					if (item.fields[this.$route.params.machine] == "comprise") {
						item["prix"] = 0;
						options_services.push(item);
					} else {
						let prix_distrib = parseInt(item.fields[this.$route.params.machine]);
						let difference = Math.round(prix_distrib * this.$store.state.offre_active.gamme_de_prix_options[0]);
						item["prix"] = prix_distrib + difference;
						item.masked = true;
						if (item.fields.reserve_picot) {
							item["modifiable"] = false;
						} else {
							item["modifiable"] = true;
						}
						if (this.$store.state.user.role.administrator) {
							options_services.push(item);
						} else {
							if (!item.fields.reserve_picot) {
								options_services.push(item);
							}
						}
					}
				}
			});
		
			//config_options_commandes
			this.options["options_machines"] = this.$store.state.offre_active.config_options_machine ? this.mergeObjects(options_machines, JSON.parse(this.$store.state.offre_active.config_options_machine)) : options_machines;
			this.options["options_accessoires"] = this.$store.state.offre_active.config_options_accessoires ? this.mergeObjects(options_accessoires, JSON.parse(this.$store.state.offre_active.config_options_accessoires)) : options_accessoires;
			this.options["options_services"] = this.$store.state.offre_active.config_options_services ? this.mergeObjects(options_services, JSON.parse(this.$store.state.offre_active.config_options_services)) : options_services;
			this.option_ready = true;
			setTimeout(() => {
				this.makeTotal();
			}, 1000);
		},
	},
};
</script>

<style lang="less">
#page6 .layout {
	display: block;
	word-break: break-word;
	h1 {
		color: #f18b00;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		color: #f18b00;
		margin-bottom: 0px;

		span {
			color: black;
		}
	}

	h2 {
		color: lighten(black, 60%);
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		margin-bottom: 0px;
		margin-top: 0;
	}

	h3 {
		position: relative;
		font-weight: bold;
		color: black;
		font-size: 30px;
		display: flex;
		flex-direction: column;
		margin: 0;

		span {
			color: #f18b00;
		}

		&:before {
			height: 10px;
			width: 55px;
			background: #f18b00;
			border: none;
			content: "";
			margin-bottom: 8px;
		}
	}

	.part {
		display: flex;
		margin-bottom: 70px;

		&.first {
			margin-top: 120px;
		}

		.gauche {
			flex: 0 0 300px;
			word-break: break-all;
			word-break: break-word;
			h6 {
				font-size: 18px;
				color: lighten(black, 60%);
				margin: 0;
				font-weight: bold;
			}

			.scalable {
				display: flex;
				align-items: center;
				font-size: 20px;
				line-height: 24px;
				margin: 20px 0;
				font-weight: bold;

				div {
					padding: 10px;

					strong {
						color: #f18b00;
						text-transform: uppercase;
					}
				}

				img {
					width: 70px;
					height: auto;
				}
			}
		}

		.droite {
			flex: 1;
			padding-left: 80px;

			hr {
				border: none;
				height: 2px;
				background: #f18b00;
				display: block;
				margin-bottom: 10px;
			}

			ol,
			ul {
				list-style: decimal inside;
				padding: 0;

				li {
					list-style: none;
					justify-content: space-between;
					padding: 5px 0;
					border-radius: 6px;
					position: relative;
					padding-left: 20px;

					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 14px;
						width: 10px;
						height: 3px;
						background: #f18b00;
					}
				}
			}

			.puceor:last-child {
				.photo {
					span {
						display: none;
					}
				}
			}
			.galerie_accessoires {
				display: flex;
				flex-wrap: nowrap;
				margin-top: 120px;
				.image {
					margin-right: 15px;
					flex: 1;
					img {
						width: 100%;
						height: auto;
						margin-bottom: 10px;
					}
					legend {
						font-size: 12px;
						color: lighten(black, 50%);
					}
				}
			}
		}
	}
}
</style>
